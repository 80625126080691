<template>
  <div class="post-basic-layout-component">
    <header class="header-post-component"><h1>&lt;header&gt;</h1></header>
    <nav class="left-sidebar">
      <b>Left</b><br />
      &lt;nav&gt; <br />
      &lt;aside&gt; <br />
      &lt;section&gt;
    </nav>
    <main class="post-basic-layout-component-main">
      &lt;main&gt; <br />
      &lt;article&gt; <br />
      &lt;section&gt;
    </main>
    <section class="right-sidebar">
      <b>Right</b><br />
      &lt;aside&gt; <br />
      &lt;section&gt;
    </section>
    <footer>&lt;footer&gt;</footer>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.post-basic-layout-component {
  display: grid;
  height: 360px;
  grid-template: auto 1fr auto / auto 1fr auto;
  width: 100%; /* Fixed width for consistency */
  /* padding: 1em; */
  margin: 0 auto; /* Center the container */
  box-sizing: border-box;
  border: solid 5px;
  border-radius: 5px;

  .header-post-component {
    background: #488eff;
    padding-left: 10px;
    grid-column: 1 / 4;
    margin: 5px;
  }

  .left-sidebar {
    background: #ff0065;
    grid-column: 1 / 2;
    margin: 5px;
  }

  .post-basic-layout-component-main {
    background: #866ae9;
    grid-column: 2 / 3;
    color: inherit;
    margin: 5px !important;
    padding: 15px;
    height: auto;
  }

  .right-sidebar {
    background: #00b054;
    grid-column: 3 / 4;
    margin: 5px;
  }

  footer {
    background: #4b50ff;
    padding: 1rem;
    text-align: center;
    grid-column: 1 / 4;
    margin: 5px;
  }

  .left-sidebar,
  .right-sidebar {
    padding: 1rem;
  }
}

/* Ensure the layout remains fixed on smaller screens */
@media (max-width: 960px) {
  .container {
    /* Scale down the layout on smaller screens */
    width: 100%; /* Keep the layout centered */
    overflow: hidden;
  }
}

@media (max-width: 768px) {
  .container {
    /* Further scale down for mobile */
  }
}

@media (max-width: 480px) {
  .container {
    /* Mobile devices get a smaller scale */
  }
}
</style>
