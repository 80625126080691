// vue babel plugin doesn't support pragma replacement
import { mdx } from '@mdx-js/vue'
let h;
/* @jsxRuntime classic */
/* @jsx mdx */
import LayoutBasics from '../components/posts/HTML_and_Semantics_Building_Meaningful_Web_Content/LayoutBasics.vue';
import MdxLink from '../components/MdxLink.vue';


const layoutProps = {
  
};
const MDXLayout = "wrapper"
function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h4 id="table-of-contents">Table of contents:</h4>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#introduction"
        }}>{`Introduction`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#what-is-semantic-html"
        }}>{`What is Semantic HTML`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#why-is-semantic-html-important"
        }}>{`Why is Semantic HTML Important?`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#common-semantic-html-elements"
        }}>{`Common Semantic HTML Elements`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#additional-semantic-html-elements"
        }}>{`Additional Semantic HTML Elements`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#browser-support-and-considerations"
        }}>{`Browser Support and Considerations`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#best-practices-for-writing-semantic-html"
        }}>{`Best Practices for Writing Semantic HTML`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#aria-and-accessibility"
        }}>{`ARIA and Accessibility`}</a></li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "#conclusion"
        }}>{`Conclusion`}</a></li>
    </ol>
    <h2 id="introduction">
  Introduction
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Semantic HTML forms the backbone of well-structured web content. In today’s world, where
accessibility, maintainability, and SEO are key to a website’s success, using semantic elements
ensures that your content is not just human-readable but also machine-understandable, aiding
search engines and assistive technologies.`}</p>
    <p>{`This post explores what semantic HTML is, why it is crucial, how it improves user experience
and code quality.`}</p>
    <h2 id="what-is-semantic-html">
  What is Semantic HTML
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Semantic HTML refers to using HTML elements for their intended purpose. Instead of relying
solely on generic containers like `}<inlineCode parentName="p">{`<div>`}</inlineCode>{`, semantic HTML introduces elements like `}<inlineCode parentName="p">{`<header>`}</inlineCode>{`,
`}<inlineCode parentName="p">{`<nav>`}</inlineCode>{`, `}<inlineCode parentName="p">{`<article>`}</inlineCode>{`, and `}<inlineCode parentName="p">{`<footer>`}</inlineCode>{` that define the structure of a webpage with clear meaning.`}</p>
    <p>{`These elements aren't just for aesthetics; they communicate the role of content sections to
browsers, search engines, and assistive technologies. For example, `}<inlineCode parentName="p">{`<header>`}</inlineCode>{` indicates
introductory or navigational content, while `}<inlineCode parentName="p">{`<article>`}</inlineCode>{` represents a self-contained piece like
a blog post or news article.`}</p>
    <h2 id="why-is-semantic-html-important">
  Why is Semantic HTML Important?
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Semantic HTML offers significant advantages in web development:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Accessibility`}</strong>{`: Semantic HTML helps assistive technologies like screen readers better
interpret page structure, making websites more inclusive for people with disabilities.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`SEO`}</strong>{`: Search engines like Google use semantic HTML structure to index and rank content.
Correct use of headings, sections, and articles can improve search visibility and page ranking.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Maintainability`}</strong>{`: Properly structured HTML is easier to read, understand, and maintain,
especially for long-term or large projects.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Collaboration`}</strong>{`: Semantic elements provide a clear structure that simplifies collaboration
between developers, designers, and content creators, making it easier for teams to
understand how each section fits into the overall layout.`}</p>
      </li>
    </ul>
    <h2 id="common-semantic-html-elements">
  Common Semantic HTML Elements
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Here are some commonly used semantic elements in HTML and their functions:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<header>`}</inlineCode></strong>{` : Defines the header for a section or page, often containing introductory
content or navigation links.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<nav>`}</inlineCode></strong>{` : Defines a section containing navigation links, helping search engines and users
understand site structure.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<article>`}</inlineCode></strong>{` : Represents a self-contained piece of content that stands alone, such as a
blog post or news story.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<section>`}</inlineCode></strong>{` : Groups related content into logical sections, usually with a heading.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<aside>`}</inlineCode></strong>{` : Contains tangentially related content, often in a sidebar.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<footer>`}</inlineCode></strong>{` : Defines the footer of a section or page, usually containing information like
author details, copyright, or links.`}</p>
      </li>
    </ul>
    <p>{`These elements are crucial to building accessible, well-structured webpages.`}</p>
    <h3>{`Example:`}</h3>
    <h4>{`Holy Grail Layout`}</h4>
    <p>{`Here is below an example of how these different elements can be used in a Web page to structure a layout
, it's also known as the `}<strong parentName="p"><em parentName="strong">{`Classic Holy Grail Layout`}</em></strong></p>
    <LayoutBasics mdxType="LayoutBasics" />
    <p>{`The magic around this layout is that it could be achieved in 1 single line of code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`grid-template: auto 1fr auto / auto 1fr auto;
`}</code></pre>
    <p>{`In case you want to play with a similar example, take the full code snippet below and have fun ^^`}</p>
    <p>{`More examples of well known layouts can be found in my other post: `}<MdxLink to="/post/Exploring_Well_Known_CSS_Layouts" mdxType="MdxLink">{`Exploring Well Known CSS Layouts`}</MdxLink></p>
    <h4>{`html`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<html>
  <body>
    <header>
      <h1>'Header'</h1>
    </header>
    <nav>Left Sidebar</nav>
    <main>Main content</main>
    <section>Right Sidebar</section>
    <footer>Footer</footer>
  </body>
</html>
`}</code></pre>
    <h4>{`CSS`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`body {
  display: grid;
  height: 100vh;
  grid-template: auto 1fr auto / auto 1fr auto;
}

header {
  background: lightpink;
  grid-column: 1 / 4;
  padding-left: 10px;
}

nav {
  background: lightblue;
  grid-column: 1 / 2;
}

main {
  background: coral;
  grid-column: 2 / 3;
  padding: 15px;
}

section {
  background: yellow;
  grid-column: 3 / 4;
}

footer {
  background: wheat;
  padding: 2rem;
  text-align: center;
  grid-column: 1 / 4;
}

.left-sidebar,
.right-sidebar {
  padding: 1rem;
}
`}</code></pre>
    <h2 id="additional-semantic-html-elements">
  Additional Semantic HTML Elements
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`While we've covered some of the most common semantic HTML tags, there are other
important ones you should be aware of to ensure your pages are fully optimized:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<figure>`}</inlineCode></strong>{`: Used to represent self-contained content like images or diagrams. It
often works with `}<inlineCode parentName="p">{`<figcaption>`}</inlineCode>{` to provide a caption.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<figcaption>`}</inlineCode></strong>{`: Provides a caption or description for content enclosed in a
`}<inlineCode parentName="p">{`<figure>`}</inlineCode>{` element.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<address>`}</inlineCode></strong>{`: Contains contact information for the author or owner of a document.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<time>`}</inlineCode></strong>{`: Represents a specific time, date, or both, making it useful for events or
timelines.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<mark>`}</inlineCode></strong>{`: Highlights text that has relevance to the surrounding content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<blockquote>`}</inlineCode></strong>{`: Used for quoting blocks of text from other sources.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<cite>`}</inlineCode></strong>{`: Used to reference the title of a work or a citation.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<details>`}</inlineCode></strong>{`: Used to specify additional details the user can view or hide.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p"><inlineCode parentName="strong">{`<summary>`}</inlineCode></strong>{`: Provides a summary for the content of a `}<inlineCode parentName="p">{`<details>`}</inlineCode>{` element.`}</p>
      </li>
    </ul>
    <p>{`Including these additional elements ensures you’re following best practices for structuring
your content in a way that maximizes readability, accessibility, and overall usability.`}</p>
    <p>{`HTML element - Full list `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element"
      }}>{`here`}</a></p>
    <h2 id="browser-support-and-considerations">
  Browser Support and Considerations
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`When working with semantic HTML elements, it’s important to keep browser support in mind.
Fortunately, most modern browsers, including Chrome, Firefox, and Safari, provide full support
for these tags. However, for older browsers (like Internet Explorer), developers may need to
implement polyfills or use a combination of semantic HTML with ARIA attributes to ensure that
assistive technologies still function as intended. Always test your pages in multiple browsers
to guarantee that all users, regardless of their chosen platform, have an optimal experience.`}</p>
    <h2 id="best-practices-for-writing-semantic-html">
  Best Practices for Writing Semantic HTML
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`To get the most out of semantic HTML, keep these best practices in mind:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Use elements for their intended purpose`}</strong>{`: Always use HTML elements based on their
meaning. For instance, use `}<inlineCode parentName="p">{`<article>`}</inlineCode>{` for standalone content and `}<inlineCode parentName="p">{`<section>`}</inlineCode>{` for
grouping related content.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Avoid excessive nesting`}</strong>{`: Over-nesting can confuse both users and machines trying
to interpret the structure.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ensure accessibility`}</strong>{`: Use elements like `}<inlineCode parentName="p">{`<nav>`}</inlineCode>{` for navigation and `}<inlineCode parentName="p">{`<main>`}</inlineCode>{` for
the main content to assist screen readers in navigating the page effectively.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Balance semantics and styling`}</strong>{`: Remember, semantic HTML focuses on meaning, while
CSS handles presentation. Keep HTML clean and focused on structure, leaving visual
design to CSS.`}</p>
      </li>
    </ul>
    <h2 id="aria-and-accessibility">
  ARIA and Accessibility
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`One of the greatest benefits of semantic HTML is how it enhances accessibility. Semantic
elements help screen readers understand the structure and content of a page, but sometimes
additional context is required. This is where ARIA (Accessible Rich Internet Applications)
comes into play. ARIA roles and attributes can complement semantic HTML, ensuring that
dynamic content, like interactive widgets or modal dialogs, is properly announced to assistive
technologies. Combining ARIA with semantic HTML ensures that your content is accessible to
everyone, regardless of their abilities.`}</p>
    <h2 id="conclusion">
  Conclusion
  <a href="#table-of-contents">↑ go up</a>
    </h2>
    <p>{`Semantic HTML is essential for creating web content that is accessible, maintainable, and
easily understood by users and search engines. By using the right elements for their
intended purpose, developers can improve SEO, enhance accessibility, and ensure code
maintainability.`}</p>
    <p>{`With tools like the Semantic Page Structure Editor and the Form Accessibility Checker,
developers can experience firsthand how semantics can improve their projects.`}</p>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;

export default {
  name: 'Mdx',
  inject: {
    $mdxComponents: {
      default: () => () => ({})
    }
  },
  computed: {
    components() {
      return this.$mdxComponents()
    }
  },
  render(createElement) {
    h = mdx.bind({ createElement, components: this.components })
    return MDXContent({ components: this.components })
  }
}
   